/* You can add global styles to this file, and also import other style files */
:root {
  --color-light-gray: #acacac;
  --color-error: #ff3b3b;
  --color-error-2: #e53535;
  --color-yellow: #e5b800;
  --color-green: #05a660;
  --color-white-50: #fdfdfd;
  --color-white-100: #f7f7f7;
  --color-violate-100: #dbc9ff;
  --color-blue-50: #effcfd;
  --color-blue-100: #ccf6f7;
  --color-blue-200: #b4f1f4;
  --color-blue-400: #7de7eb;
  --color-blue-500: #5ce1e6;
  --color-blue-600: #54cdd1;
  --color-blue-900: #3b226b;
  --color-dark-grey-50: #eaeaeb;
  --color-dark-grey-300: #747576;
  --color-dark-grey-500: #303133;
  --color-dark-grey-900: #141515;
}
:host {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #000;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
html {
  margin: 0;
  box-sizing: border-box;
}
body.noscroll {
  overflow: hidden;
}
img {
  max-width: 100%;
}
a,
b,
blockquote,
body,
button,
canvas,
caption,
code,
div,
em,
embed,
fieldset,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
input,
label,
li,
main,
nav,
object,
ol,
p,
pre,
section,
select,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
a,
a:visited,
a:hover {
  color: #1976d2;
  text-decoration: none;
}
a {
  cursor: pointer;
}
a:hover {
  color: #125699;
}
input {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.container {
  max-width: 1232px;
  margin: 0 auto;
  padding-inline: 16px;
  width: 100%;
  @media screen and (max-width: 1024px) {
    max-width: 700px;
  }
}
video {
  max-width: 100%;
}

.range-slider {
  --c: #01ffff; /* active color */
  --g: 0px; /* the gap */
  --l: 4px; /* line thickness*/
  --s: 20px; /* thumb size*/
  width: 100%;
  height: 20px; /* needed for Firefox*/
  @media screen and (max-width: 1024px) {
    --s: 12px;
    --l: 2px;
  }
  @media screen and (max-width: 767px) {
    --s: 12px;
    --l: 2px;
  }
  --_c: color-mix(in srgb, var(--c), #000 var(--p, 0%));
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
}
.range-slider:focus-visible,
.range-slider:hover {
  --p: 25%;
}
.range-slider:active,
.range-slider:focus-visible {
  --_b: 20px;
}
/* chromium */
.range-slider[type='range' i]::-webkit-slider-thumb {
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--_c);
  box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
  border-image: linear-gradient(90deg, var(--_c) 50%, #e9e9e9 0) 1/0 100vw/0 calc(100vw + var(--g));
  clip-path: polygon(
    0 calc(50% + var(--l) / 2),
    -100vw calc(50% + var(--l) / 2),
    -100vw calc(50% - var(--l) / 2),
    0 calc(50% - var(--l) / 2),
    0 0,
    100% 0,
    100% calc(50% - var(--l) / 2),
    100vw calc(50% - var(--l) / 2),
    100vw calc(50% + var(--l) / 2),
    100% calc(50% + var(--l) / 2),
    100% 100%,
    0 100%
  );
  -webkit-appearance: none;
  appearance: none;
  transition: 0.3s;
  @media screen and (max-width: 1024px) {
    height: 12px;
  }
  @media screen and (max-width: 767px) {
    height: 12px;
  }
}
/* Firefox */
.range-slider[type='range']::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background: var(--_c);
  border-radius: 50%;
  box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
  border-image: linear-gradient(90deg, var(--_c) 50%, #ababab 0) 1/0 100vw/0 calc(100vw + var(--g));
  clip-path: polygon(
    0 calc(50% + var(--l) / 2),
    -100vw calc(50% + var(--l) / 2),
    -100vw calc(50% - var(--l) / 2),
    0 calc(50% - var(--l) / 2),
    1px 0,
    100% 0,
    100% 100%,
    1px 100%
  );
  -moz-appearance: none;
  appearance: none;
  transition: 0.3s;
}
@supports not (color: color-mix(in srgb, red, red)) {
  input {
    --_c: var(--c);
  }
}
form {
  display: grid;
  gap: 12px;
  @media screen and (max-width: 767px) {
    gap: 8px;
  }
}
.grid {
  display: grid;
  grid-auto-flow: column;
  &___space-between {
    justify-content: space-between;
    align-items: center;
  }
  &___same {
    grid-auto-columns: 1fr;
    align-items: start;
  }
}
.input {
  display: grid;
  gap: 8px;
  @media screen and (max-width: 767px) {
    gap: 4px;
  }
  &__row {
    position: relative;
    @media screen and (max-width: 767px) {
      justify-content: initial;
      gap: 16px;
    }
    &__btn {
      position: absolute;
      right: 0;
      border-radius: 32px;
      background: linear-gradient(90deg, #8c52ff 0%, #5ce1e6 100%);
      font-size: 12px;
      color: var(--color-white-50);
      font-weight: 500;
      width: 100px;
      height: 32px;
      top: 8px;
      display: grid;
      place-content: center;
      place-items: center;
      right: 12px;
      text-transform: uppercase;
      @media screen and (max-width: 767px) {
        font-size: 10px;
        width: 80px;
        height: 24px;
        top: 4px;
        right: 4px;
      }
    }
  }

  label {
    font-size: 24px;
    color: var(--color-white-100);
    font-weight: 500;
    line-height: 130%;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    span {
      color: var(--color-blue-600);
    }
  }
  input,
  select,
  textarea {
    border-bottom: 1px solid var(--color-blue-100);
    background: rgba(180, 241, 244, 0.32);
    padding: 12px 14px;
    color: var(--color-light-gray);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 100%;
    resize: none;
    border-radius: 0;
    @media screen and (max-width: 767px) {
      padding: 8px 6px;
      font-size: 10px;
    }
  }
  &__errorMsg {
    display: none;
  }
  &___error {
    input,
    select,
    textarea {
      border-color: var(--color-error);
      background-image: url('../src/assets/images/error-indicator.svg');
      background-repeat: no-repeat;
      background-position: center right 14px;
      @media screen and (max-width: 767px) {
        background-position: center right 6px;
        background-size: 16px;
        font-size: 10px;
      }
    }
    .input__errorMsg {
      display: grid;
      color: #f04438;
      font-size: 14px;
      font-weight: 500;
      line-height: 130%;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}
.form-submit-btn {
  padding: 12px 18px;
  width: 180px;
  height: 44px;
  display: grid;
  font-size: 16px;
  color: var(--color-white-50);
  font-weight: 500;
  line-height: 130%;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: linear-gradient(90deg, #8c52ff 0%, #5ce1e6 100%);
  margin: 0 auto;
  margin-top: 12px;
  @media screen and (max-width: 767px) {
    margin-top: 4px;
    width: 100%;
    height: 32px;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
  }
}
.form-back-btn {
  padding: 12px 18px;
  width: 180px;
  height: 44px;
  display: grid;
  font-size: 16px;
  color: var(--color-white-50);
  font-weight: 500;
  line-height: 130%;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  border-radius: 32px;
  border: 1px solid #8c52ff;
  background: linear-gradient(90deg, #8c52ff 0%, #5ce1e6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  margin-top: 12px;
  @media screen and (max-width: 767px) {
    margin-top: 4px;
    width: 100%;
    height: 32px;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
  }
}
input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.gap___df {
  gap: 16px;
}
.checkbox {
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  input {
    accent-color: #ccf6f7;
    width: 16px;
    height: 16px;
    -webkit-appearance: auto;
    border-radius: 2px;
    border: 1.1px solid var(--primary-colors-blue-100, #ccf6f7);
    background: rgba(180, 241, 244, 0.32);
    margin-top: 4px;
  }
  label {
    font-size: 16px;
    color: var(--color-white-100);
    font-weight: 500;
    line-height: 130%;
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
}
.yellow {
  color: var(--color-yellow);
}
.green {
  color: var(--color-green);
}
.red {
  color: var(--color-error-2);
}
.blue {
  color: var(--color-blue-500);
}
.loaderBody {
  position: fixed;
  inset: 0 0 0 0;
  background: rgba(0, 0, 0, 0.75);
  display: grid;
  place-content: center;
  place-items: center;
  &__loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #fff #fff transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  &__loader::after,
  &__loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #89f2ff #89f2ff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  &__loader::before {
    width: 32px;
    height: 32px;
    border-color: #fff #fff transparent transparent;
    animation: rotation 1.5s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}
