// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette(mat.$indigo-palette);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: mat.define-typography-config(),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper,
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: transparent !important;
  padding: 0;
  border-radius: 0;
}
// .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
//   caret-color: initial;
// }
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 0;
  min-height: initial;
}
.mat-mdc-form-field-icon-suffix {
  padding: 0 !important;
  position: absolute !important;
  right: 0;
  top: 0;
  display: grid;
  place-content: center;
  place-items: center;
}
.mat-datepicker-toggle {
  color: #ffffff81 !important;
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.mat-mdc-icon-button .mat-mdc-button-ripple,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  display: none !important;
}
.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  border-bottom: 1px solid var(--color-blue-100);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  height: 100% !important;
  color: #acacac !important;
}
input[isdisabled='true'] {
  pointer-events: none;
  opacity: 0.8;
}
@media screen and (max-width: 767px) {
  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 100% !important;
    font-size: 10px !important;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 24px;
    padding: 0;
    display: grid;
    place-content: center;
    place-items: center;
  }
  .mat-mdc-form-field-icon-suffix {
    top: 4px;
    right: 4px;
  }
  .mat-mdc-icon-button svg {
    width: 16px !important;
    height: 16px !important;
  }
  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: 100% !important;
    width: 100% !important;
  }
}
